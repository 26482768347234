import React from 'react';
import './Introduction.css';
import EmployeeCard from '../cards/employeeCard/employeeCard';
import Introduction from './companyIntro';

export default function ContactSection() {
  return (
    <div className='contact-wrapper'>
    <section className="contact-content">
     <h1>Kontakt Oss!</h1>
     <div className='contact-top-spacer'></div>
     <p className='contact-title'>Har du en henvendelse?</p>
     
     <div className='contact-text-and-link'>
     <p className='contact-text'>Kontakt oss på </p><a className='contact-text-link' href='mailto:support@xinit.no'>support@xinit.no</a>
     </div>

     <div className='contact-spacer'></div>

    </section>
    <Introduction/>
    <EmployeeCard/>
    </div>
  );
}