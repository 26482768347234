import React, { useState, useContext} from 'react';
import './menus.css';
import NavButton from '../buttons/navButton';
import exitButton from '../../assets/exit-icon.png';
import { SelectedContext } from '../../utils/selectedContext';
import NavHrefButton from '../buttons/navHrefButton';

export default function NavBurgerMenu() {
    const [showMenu, setShowMenu] = useState(false);
    const { burgerMenuPressed, setBurgerMenuPressed } = useContext(SelectedContext);

    function toggleMenu() {
        setBurgerMenuPressed(true);
        setShowMenu(prevState => !prevState);
    }

    function getMenuClass() {
        return showMenu ? 'fade-in' : 'fade-out';
    }

    return (
        <>
            <div className="nav_burger-menu" onClick={toggleMenu}>
                <div className='nav__burger-menu-button'>
                    <div className="burger-bar"></div>
                    <div className="burger-bar"></div>
                    <div className="burger-bar"></div>
                </div>
            </div>
            {burgerMenuPressed && (
                <>
                    <div className={`nav_burger-menu-content ${getMenuClass()}`}>
                        <div className='nav_burger-menu-exit-button' onClick={toggleMenu}>
                            <img src={exitButton} alt="exit button" className="nav_burger-menu-exit-icon" />
                        </div>
                        <div className='nav_burger-menu_button-wrapper'>
                            <NavButton type='Hjem' page='' onClick={toggleMenu} />
                            <NavHrefButton type='Xamin App' page='https://xamin.app/wp/'/>
                            <NavButton type='Kontakt' page='contact' onClick={toggleMenu} />
                        </div>
                    </div>
                    <div className={`nav_burger-menu-blur ${getMenuClass()}`}></div>
                </>
            )}
        </>
    );
}