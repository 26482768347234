import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Homepage from './pages/homepage';
import About from './pages/about';
import Contact from './pages/contact';
import Products from './pages/products';
import Error from './pages/error'
import ScrollToTop from './utils/scroll-to-top';
import ContactSection from './components/introSections/contact';

export default function App() {

  return (
    <div className='app'>
       <ScrollToTop/>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactSection />} />
          <Route path="https://xamin.app/wp/" element={<Products />} />
          <Route path="/*" element={<Error />} />
        </Routes>
        <Footer />
    </div>
  );
}